import { Modal } from "antd";
import { IDLE_CONFIRM_MODAL_SECONDS, IDLE_SECONDS } from "appConfig";
import { useCallback, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { setAuth } from "redux/authSlice";
import { useAppDispatch } from "redux/hooks";
import { CountdownInfo } from "./CountdownInfo";

export const InactivityTimeoutDialog = () => {
  const dispatch = useAppDispatch();
  const [inactiveModalVisible, setInactiveModalVisible] = useState(false);

  const logout = useCallback(() => {
    window.sessionStorage.setItem("storageToken", "");
    dispatch(
      setAuth({
        token: "",
      })
    );
    window.location.href = "/";
  }, [dispatch]);

  const onPrompt = useCallback(() => {
    setInactiveModalVisible(true);
  }, []);

  const idleLogoutTimer = useIdleTimer({
    onIdle: logout,
    onPrompt,
    promptTimeout: IDLE_CONFIRM_MODAL_SECONDS * 1000,
    timeout: IDLE_SECONDS * 1000,
  });

  console.log(idleLogoutTimer.getRemainingTime());

  return (
    <Modal
      visible={inactiveModalVisible}
      title="Session timeout warning"
      cancelText="End session now"
      onCancel={() => {
        setInactiveModalVisible(false);
        logout();
      }}
      okText="Continue session"
      onOk={() => {
        idleLogoutTimer.start();
        setInactiveModalVisible(false);
      }}
      closable={false}
      maskClosable={false}
    >
      <CountdownInfo
        idleTimer={idleLogoutTimer}
        onLogout={logout}
        onClose={() => setInactiveModalVisible(false)}
      />
    </Modal>
  );
};
