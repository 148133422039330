import { Footer } from "features/layout/Footer";
import { Header } from "features/layout/Header";
import { SideBar } from "features/SideBar";
import React, { useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { DrawerType } from "types/drawer-type";

interface Props {
  id?: string;
  children: any;
  bgColor?: string;
}

export const PageContainer = ({
  id,
  children,
  bgColor = "snow",
}: Props): JSX.Element => {
  const auth = useAppSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const [drawerType, setDrawerType] = useState<DrawerType>(DrawerType.U);

  const onClose = () => {
    setVisible(false);
  };
  const onDrawerChange = useCallback((drawerType: DrawerType) => {
    setDrawerType(drawerType);
    setVisible(true);
  }, []);

  return (
    <div
      className={`relative flex flex-col min-h-full overflow-x-hidden text-base font-MuseoSans  bg-${bgColor}`}
    >
      <Header userLogged={!!auth.token} onDrawerChange={onDrawerChange} />
      <div
        id={id}
        className="relative z-0 flex flex-col items-center flex-grow"
      >
        {children}
        <SideBar
          changeDrawerContent={onDrawerChange}
          drawerType={drawerType}
          onClose={onClose}
          visible={visible}
        />
      </div>
      <Footer />
    </div>
  );
};
