import { configureStore } from "@reduxjs/toolkit";
import userReducer, { UserState } from "./userSlice";
import navigationReducer, { NavigationState } from "./navigationSlice";
import authReducer, { AuthState } from "./authSlice";

export interface StoreType {
  user: UserState;
  navigation: NavigationState;
  auth: AuthState;
}

export const store = configureStore<StoreType>({
  reducer: {
    user: userReducer,
    navigation: navigationReducer,
    auth: authReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
