import { BaseDatePicker } from "features/enhanced-controls/BaseDatePicker";
import { FormField } from "types/form-field";
import { FormValuesNew } from "types/form-values-new";
import { useFormNew } from "hooks/useForm";
import { ButtonDarkRounded } from "common/ButtonDarkRounded";
import { useApi } from "hooks/useApi";

const initValues: FormValuesNew = new Map<string, FormField>();

initValues.set("date-from", {
  value: "",
  required: true,
  placeholder: "Date from",
});
initValues.set("date-to", {
  value: "",
  required: true,
  placeholder: "Date to",
});

export const ExportInvoicesForm = () => {
  const {
    draftValues,
    fieldValidation,
    formHasErrors,
    allRequiredHaveValues,
    onFieldValueChange,
  } = useFormNew(initValues);

  const { makeGetRequest } = useApi();

  const onSubmit = () => {
    fieldValidation("date-from", draftValues.get("date-from").value);
    fieldValidation("date-to", draftValues.get("date-to").value);
    if (formHasErrors || !allRequiredHaveValues) {
      return;
    }
    const url = `/?act=op_export_invoices&start_date=${(
      draftValues.get("date-from").value as moment.Moment
    ).format("YYYY-MM-DD")}&end_date=${(
      draftValues.get("date-to").value as moment.Moment
    ).format("YYYY-MM-DD")}`;

    makeGetRequest({
      url,
      config: {
        responseType: "blob",
      },
    }).then((res: any) => {
      // console.log({ res }, res.length);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "archive.zip"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className="p-4 flex flex-col gap-4">
      <BaseDatePicker
        id="date-from"
        onChange={(id: string, value: string | moment.Moment) => {
          onFieldValueChange(id, value);
        }}
        onValidation={fieldValidation}
        placeholder={draftValues.get("date-from").placeholder}
        value={draftValues.get("date-from").value as moment.Moment}
        error={draftValues.get("date-from").error}
      />

      <BaseDatePicker
        id="date-to"
        onChange={(id: string, value: string | moment.Moment) => {
          onFieldValueChange(id, value);
        }}
        onValidation={fieldValidation}
        placeholder={draftValues.get("date-to").placeholder}
        value={draftValues.get("date-to").value as moment.Moment}
        error={draftValues.get("date-to").error}
      />
      <div className="flex justify-center">
        <ButtonDarkRounded
          disabled={formHasErrors || !allRequiredHaveValues}
          onClick={onSubmit}
          caption={"Export"}
        />
      </div>
    </div>
  );
};
