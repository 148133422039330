import { Form } from "antd";
import { ButtonDarkRounded } from "common/ButtonDarkRounded";
import { BaseInput } from "features/enhanced-controls/BaseInput";
import { useFormNew } from "hooks/useForm";
import { useNavigate } from "react-router-dom";
import { FormField } from "types/form-field";
import { FormValuesNew } from "types/form-values-new";
import { RoutePath } from "utils/enums-lib";
import { BaseInputPassword } from "features/enhanced-controls/BaseInputPassword";

const initValues: FormValuesNew = new Map<string, FormField>();

initValues.set("username", {
  value: "",
  required: true,
  placeholder: "Email",
});
initValues.set("passwordLogin", {
  value: "",
  required: true,
  placeholder: "Password",
});

interface Props {
  loginError: string;
  onSubmit: (email: string, password: string) => void;
}

export const LoginForm = ({ loginError, onSubmit }: Props) => {
  const {
    draftValues,
    fieldValidation,
    formHasErrors,
    allRequiredHaveValues,
    onFieldValueChange,
  } = useFormNew(initValues);

  const navigate = useNavigate();
  const validateSubmit = () => {
    draftValues.forEach((_field: FormField, key: string) =>
      fieldValidation(key, draftValues.get(key).value as string)
    );

    if (!allRequiredHaveValues || formHasErrors) {
      return;
    }
    onSubmit(
      draftValues.get("username").value as string,
      draftValues.get("passwordLogin").value as string
    );
  };

  return (
    <>
      <Form name="login-form" autoComplete="off">
        <div className="mb-8 text-xl font-bold tracking-wide text-center text-plum">
          Login to TravelWing
        </div>

        <div className="flex flex-col gap-4">
          <BaseInput
            value={draftValues.get("username").value as string}
            error={draftValues.get("username").error}
            onChange={(e) =>
              onFieldValueChange(
                e.target.id,
                (e.target.value as string).replace(" ", "")
              )
            }
            onBlur={(e) => fieldValidation(e.target.id, e.target.value)}
            placeholder={draftValues.get("username").placeholder}
            id={"username"}
            className="h-12"
          />
          <BaseInputPassword
            value={draftValues.get("passwordLogin").value as string}
            error={draftValues.get("passwordLogin").error}
            onChange={(e) =>
              onFieldValueChange(
                e.target.id,
                (e.target.value as string).replace(" ", "")
              )
            }
            onBlur={(e) => fieldValidation(e.target.id, e.target.value)}
            placeholder={draftValues.get("passwordLogin").placeholder}
            id={"passwordLogin"}
            className="h-12"
          />
        </div>
        {loginError && (
          <div className="max-w-xs text-sm text-red-700">{loginError}</div>
        )}

        <div className="my-4 text-center mb-20">
          <ButtonDarkRounded
            caption="Login"
            onClick={() => validateSubmit()}
            noTrack
            disabled={formHasErrors || !allRequiredHaveValues}
          />
        </div>
        <div className="flex justify-center text-plum">
          <span
            onClick={() => navigate(RoutePath.forgotPassword)}
            className="cursor-pointer"
          >
            forgot password?
          </span>
          <span className="mx-2">|</span>
          <span
            onClick={() => navigate(RoutePath.forgotEmail)}
            className="cursor-pointer"
          >
            forgot email?
          </span>
        </div>
      </Form>
    </>
  );
};
