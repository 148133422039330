import { Endpoint } from "endpoints";
import { useAppSelector } from "redux/hooks";
import { todaySQL } from "utils/date-format";
import { useFetchData } from "./useFetchData";

export const useTrackEvents = () => {
  const logName = `myPlans-log-${todaySQL()}`;
  const { fetchData } = useFetchData();
  const user = useAppSelector((state) => state.user);

  const trackEvent = (payload: any, noTrack: boolean) => {
    if (!user.isDiagnosticsEnabled && noTrack) {
      return;
    }

    const old = JSON.parse(window.localStorage.getItem(logName)) || [];
    const record = {
      dateTime: new Date().toUTCString(),
      url: window.location.href,
      payload,
    };
    window.localStorage.setItem(logName, JSON.stringify([...old, record]));
  };

  const sendDiagnosticsData = () => {
    const dayData = JSON.parse(window.localStorage.getItem(logName));
    fetchData(Endpoint.support, {
      [logName]: dayData,
    });
  };

  return {
    trackEvent,
    sendDiagnosticsData,
  };
};
