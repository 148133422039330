import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserAddress } from "types/user-address";

export interface UserState {
  // logged: boolean;
  // token?: string;
  customerKey?: number;
  customerSecret?: string;
  customerRef?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob?: string;
  ni?: string;

  address?: UserAddress;

  emailAddress?: string;
  mobilePhoneNumber?: string;
  // marketingEmail?: boolean;
  // marketingSMS?: boolean;
  // membershipEmail?: boolean;
  // membershipSMS?: boolean;
  keepInformedViaSMS?: boolean;
  mpNameChanged?: boolean;
  mpMarketingStatus?: boolean;
  servicingAdviser?: string;
  hasUnreadNotification?: boolean;
  firstTimeLoggedIn?: boolean;
  isDiagnosticsEnabled?: boolean;
  iterationId: number;

  //new fields
  marketingEmailOptIn?: boolean;
  marketingSMSOptIn?: boolean;
  membershipEmailOptIn?: boolean;
  membershipSMSOptIn?: boolean;

  nameChanged?: boolean;
}

const initialState: UserState = {
  isDiagnosticsEnabled: true,
  iterationId: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: UserState, action: PayloadAction<UserState>) => ({
      ...state,
      ...action.payload,
    }),
    setAddress: (state: UserState, action: PayloadAction<UserAddress>) => ({
      ...state,
      address: action.payload,
    }),
  },
});

export const { setUser, setAddress } = userSlice.actions;
export default userSlice.reducer;
