import { FC } from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

import { DesktopMenu } from "./DesktopMenu";
import { SmileOutlined } from "@ant-design/icons";
import { LoggedUserButtons } from "./LoggedUserButtons";
import { DrawerType } from "types/drawer-type";

const { Header: HeaderAnt } = Layout;

interface Props {
  onDrawerChange: (t: DrawerType) => void;
  userLogged: boolean;
}

export const Header: FC<Props> = ({
  userLogged,
  onDrawerChange,
}): JSX.Element => {
  const headerStyle = `py-1 flex flex-col px-3 ${
    userLogged
      ? " border-b-2 bg-plum border-plumLight md:px-12 text-white"
      : " bg-white md:px-12 text-plum"
  }`;

  return (
    // <HeaderAnt className=>
    <HeaderAnt className={headerStyle}>
      <div className="flex items-center w-full">
        <div className="flex-shrink-0 w-40">
          <Link to="/portal">
            <SmileOutlined className="w-10 h-10" />
          </Link>
        </div>

        <div className="flex items-center w-full">
          {userLogged && (
            <div className="flex w-full  items-center ">
              <DesktopMenu />
            </div>
          )}
          {userLogged && <LoggedUserButtons onDrawerChange={onDrawerChange} />}
        </div>
      </div>
    </HeaderAnt>
  );
};
