import { useForcedEvent } from "hooks/useForcedEvent";
import { IIdleTimer } from "react-idle-timer";

interface Props {
  idleTimer: IIdleTimer;
  onLogout: () => void;
  onClose: () => void;
}

export const CountdownInfo = ({ idleTimer, onLogout, onClose }: Props) => {
  const { triggerEvent } = useForcedEvent();

  setTimeout(() => {
    triggerEvent();
  }, 1000);

  const remainingTimeSeconds = idleTimer.getRemainingTime() / 1000;
  const minutes = Math.floor(remainingTimeSeconds / 60);
  const seconds = Math.floor(remainingTimeSeconds % 60);

  return (
    <>
      <div>
        Your session will expire in
        {minutes > 0 ? ` ${minutes} minute(s) and ` : ""} {seconds} seconds
      </div>
      <div>Select "Continue session" to extend your session.</div>
    </>
  );
};
