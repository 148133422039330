import { PageContainer } from "features/layout/PageContainer";
import { FC, useEffect } from "react";

import { useAppSelector } from "redux/hooks";
import { useLogin } from "pages/LoginPage/hooks";
import { ExportInvoicesForm } from "features/ExportInvoicesForm";
import { WhiteCard } from "common/WhiteCard";

export const DashboardPage: FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);

  const { getUserDetails } = useLogin();

  useEffect(() => {
    if (auth.token && (!user || !user.emailAddress)) {
      getUserDetails(auth.token);
    }
  }, [auth.token, getUserDetails, user]);

  return (
    <PageContainer>
      <div className="flex items-center justify-center w-full h-full py-8 md:text-lg bg-snow text-gray-dark">
        <div className="flex flex-col w-full max-w-md gap-3 p-3">
          <WhiteCard>
            <ExportInvoicesForm />
          </WhiteCard>
        </div>
      </div>
    </PageContainer>
  );
};
