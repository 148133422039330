import { ButtonDarkRounded } from "common/ButtonDarkRounded";
import { useNavigate } from "react-router-dom";
import { setAuth } from "redux/authSlice";
import { useAppDispatch } from "redux/hooks";

export const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <div className="py-5 mb-20 font-bold">
      <ButtonDarkRounded
        onClick={() => {
          dispatch(setAuth({ token: "" }));
          window.sessionStorage.setItem("storageToken", "");
          navigate("/");
        }}
        caption="Logout"
      />
    </div>
  );
};
