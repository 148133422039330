import { format as _format } from "date-fns";

export const formatStrDate = (value: string, pattern?: string): string => {
  const d = new Date(value);
  return formatDate(d, pattern);
};

export const formatDate = (d: Date, pattern?: string) => {
  if (pattern) {
    return _format(d, pattern);
  }
  return `${_format(d, "do")} of ${_format(d, "LLL yyyy")}`;
};

export const todaySQL = (): string => {
  return _format(new Date(), "yyyy-MM-dd");
};
