import { Col, Row } from "antd";

export const Footer = (): JSX.Element => (
  <footer className="px-4 py-2 text-xs">
    <Row className="mb-2">
      <Col span={24}>
        Footer
      </Col>
    </Row>    
  </footer>
);
