import { Link } from "react-router-dom";

export const DesktopMenu = () => (
  <div className="flex-grow hidden mx-4 md:block">
    <ul className="flex gap-8">
      <li>
        <Link to="/portal" className="hover:text-gray-400">
          Home
        </Link>
      </li>
      <li>Resources</li>
      <li>Help</li>
    </ul>
  </div>
);
