import { Drawer } from "antd";
import { UserProfile } from "features/UserProfile";
import { DrawerType } from "types/drawer-type";

interface Props {
  drawerType: DrawerType;
  onClose: () => void;
  visible: boolean;
  changeDrawerContent: (DrawerType: DrawerType) => void;
}
export const SideBar = ({
  drawerType,
  onClose,
  visible,
  changeDrawerContent,
}: Props) => {
  const title =
    drawerType === DrawerType.N
      ? "Notifications"
      : drawerType === DrawerType.D
      ? "Documents"
      : "My Profile";

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      visible={visible}
      // width={460}
      className="pt-20"
    >
      {drawerType === DrawerType.N && <div>Notifications</div>}
      {drawerType === DrawerType.D && <div>Documents</div>}
      {drawerType === DrawerType.U && <UserProfile />}
    </Drawer>
  );
};
