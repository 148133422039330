import { DocumentButton } from "common/DocumentButton";
import { NotificationButton } from "common/NotificationButton";
import { UserButton } from "common/UserButton";

import { useAppSelector } from "redux/hooks";
import { DrawerType } from "types/drawer-type";

interface Props {
  onDrawerChange: (t: DrawerType) => void;
}

export const LoggedUserButtons = ({ onDrawerChange }: Props) => {
  const userData = useAppSelector((state) => state.user);

  return (
    <div className="flex items-center justify-between  px-2 gap-2">
      <div className="hidden md:block">
        <div
          onClick={() => onDrawerChange(DrawerType.D)}
          className="cursor-pointer"
        >
          <DocumentButton />
        </div>
      </div>
      <div
        onClick={() => onDrawerChange(DrawerType.N)}
        className="cursor-pointer"
      >
        <NotificationButton hasAlert={false} />
      </div>
      <div className="hidden md:block">
        <UserButton
          onClick={() => onDrawerChange(DrawerType.U)}
          userFullName={userData.firstName}
        />
      </div>
    </div>
  );
};
