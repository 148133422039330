import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "utils/enums-lib";
import { PageContainer } from "features/layout/PageContainer";
import { WhiteCard } from "common/WhiteCard";
import { useLogin } from "./hooks/useLogin";
import { LoginForm } from "./LoginForm";
import { ApiResponseLogin } from "api-response/api-response-login";
import { useAppDispatch } from "redux/hooks";
import { setAuth } from "redux/authSlice";

export const Login: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { sendCredentials, loginError } = useLogin();

  const handleSendCredentials = useCallback(
    async (email: string, password: string) => {
      sendCredentials(email, password).then((response: ApiResponseLogin) => {
        if (response.authenticated) {
          dispatch(
            setAuth({
              token: response.token,
            })
          );
          navigate(RoutePath.dashboard);
        }
      });
    },
    [dispatch, navigate, sendCredentials]
  );

  return (
    <PageContainer bgColor="white">
      <div className="flex items-center justify-center w-full h-full py-8 bg-white text-gray-dark">
        <div className="max-w-4xl p-3">
          <WhiteCard className="bg-snow">
            <LoginForm
              loginError={loginError}
              onSubmit={handleSendCredentials}
            />
          </WhiteCard>
        </div>
      </div>
    </PageContainer>
  );
};
