import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  token: string;
  // customerKey?: string;
  // customerSecret?: string;
}

const initialState: AuthState = {
  token: "",
  // customerKey: "",
  // customerSecret: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state: AuthState, action: PayloadAction<AuthState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setAuth } = authSlice.actions;
export default authSlice.reducer;
