export enum RoutePath {
  // login = "/",
  // resetEmail = "/",
  // resetPassword = "/",
  // resendOTP = "/",
  // home = "/",
  login = "/",
  register = "/register",
  registerWithStep = "/register/:step",
  registerConfirm = "/register/2",
  registerVerify = "/register/3",
  registerSuccsess = "/register/4",
  termsConditions = "/termsconditions",
  privacyPolicy = "/privacypolicy",
  dashboard = "/portal",
  benefitDetails = "/plan",
  funds = "/plan/funds",
  addMoney = "/add-money/:planRef/:benefitRef",
  takeMoney = "/take-money/:planRef/:benefitRef",
  additionalVerification = "/verify",
  fund = "/fund",
  benefitHistory = "/plan/history",
  forgotPasswordChange = "/forgot-password/:activationKey",
  forgotPassword = "/forgot-password",
  forgotEmail = "/forgot-email",

  CTFMaturedSaveAndWithdrawAmount = "/plan/ctfm-save-withdraw-amount",
}

export enum MethodType {
  POST = "post",
  PUT = "put",
  GET = "get",
  DELETE = "delete",
}
