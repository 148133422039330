import { Input } from "antd";
import { useTrackEvents } from "hooks/useTrackEvents";
import { FC } from "react";
interface Props {
  id?: string;
  name?: string;
  type?: string;
  className?: string;
  onChange?: (e: any) => void;
  onCopy?: (e: any) => void;
  onPaste?: (e: any) => void;
  onClick?: (event: any) => void;
  onBlur?: (event: any) => void;
  noTrack?: boolean;
  allowClear?: boolean;
  readOnly?: boolean;
  suffix?: any;
  inputRef?: any;
  value?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
}
export const BaseInput: FC<Props> = ({
  id,
  name,
  type,
  className,
  onChange,
  onCopy,
  onPaste,
  onClick,
  noTrack,
  allowClear,
  readOnly,
  suffix,
  inputRef,
  onBlur,
  value,
  placeholder,
  error = "",
  disabled,
}) => {
  const { trackEvent } = useTrackEvents();

  const sendData = (value: string | number, eventType: string) => {
    trackEvent(
      {
        id,
        objType: "input",
        eventType: eventType,
        value: value || "",
      },
      noTrack
    );
  };

  return (
    <div>
      <Input
        disabled={disabled}
        autoComplete={"off"}
        id={id}
        name={name}
        type={type}
        onPaste={onPaste}
        onCopy={onCopy}
        className={className}
        onClick={onClick}
        onChange={onChange}
        allowClear={allowClear}
        readOnly={readOnly}
        suffix={suffix}
        ref={inputRef}
        placeholder={placeholder}
        data-testid={`${name ?? id ?? "no-data-testid"}Field`}
        onBlur={(event: any) => {
          onBlur && onBlur(event);
          sendData(event?.target?.value || "", "blur");
        }}
        onFocus={(event: any) => {
          sendData(event?.target?.value || "", "focus");
        }}
        {...(value !== undefined && { value })}
        {...(!!error && { style: { border: "1px solid red" } })}
      />
      {!!error && <div className="text-xs text-red-800">{error}</div>}
    </div>
  );
};
