import { BellIcon } from "assets/icons/BellIcon";
import { FC } from "react";

interface Props {
  hasAlert: boolean;
}

export const NotificationButton: FC<Props> = ({ hasAlert }) => (
  <div className="relative">
    <div className="relative flex items-center justify-center w-8 h-8 text-xl border-2 border-pink-900 rounded-full">
      <BellIcon />
    </div>

    {hasAlert && (
      <div className="absolute w-3 h-3 bg-yellow-400 rounded-full right-1 top-1" />
    )}
  </div>
);
