import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { RoutePath } from "utils/enums-lib";
import { Login } from "pages/LoginPage";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import { setAuth } from "redux/authSlice";
import { InactivityTimeoutDialog } from "features/InactivityTimeout/InactivityTimeoutDialog";
import { DashboardPage } from "pages/DashboardPage/DashboardPage";

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAppSelector((state) => state.auth);

  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" />;
  }

  return children;
}

function RequireNotAuth({ children }: { children: JSX.Element }) {
  const auth = useAppSelector((state) => state.auth);

  if (auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/portal" />;
  }

  return children;
}

export default function App() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    const storageToken = window.sessionStorage.getItem("storageToken");

    if (!auth.token && storageToken) {
      dispatch(
        setAuth({
          token: storageToken,
        })
      );
    }
  }, [auth, dispatch]);

  return (
    <div className="h-full text-base">
      {auth.token && <InactivityTimeoutDialog />}
      <Router>
        <Routes>
          <Route
            path={RoutePath.login}
            element={
              <RequireNotAuth>
                <Login />
              </RequireNotAuth>
            }
          />
          <Route
            path={RoutePath.dashboard}
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}
