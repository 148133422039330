import axios from "axios";
import { useCallback } from "react";

export const useFetchData = () => {
  const fetchData = useCallback(async (url: string, payload: any) => {
    const data = await axios.post<any>(url, {
      headers: {
        Authorization: "BASIC blabla-token",
      },
      data: payload,
    });

    return data;
  }, []);
  return {
    fetchData,
  };
};
