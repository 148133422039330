import { EnhancedStore } from "@reduxjs/toolkit";
import axios from "axios";
import { StoreType } from "redux/store";

let store: EnhancedStore<StoreType>;

export const injectStore = (_store: EnhancedStore<StoreType>) => {
  store = _store;
};

axios.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${store.getState().auth.token}`,
  };
  return config;
});
