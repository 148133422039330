import axios from "axios";
import { useCallback } from "react";
// import { useAppSelector } from "redux/hooks";

export const useApi = () => {
  const isMockUrl = useCallback((url: string): string => {
    const server = process.env.REACT_APP_API_SERVER_URL;
    return `${server}${url}`;
  }, []);

  interface IGetRequestProps {
    url: string;
    queryParams?: any;
    config?: any;
    responseType?: string;
  }

  const makeGetRequest = useCallback(
    async (props: IGetRequestProps): Promise<any> => {
      const { url, queryParams, config = {} } = props;
      const requestUrl = isMockUrl(url);

      return axios
        .get(requestUrl, {
          withCredentials: true,
          ...config,
        })
        .then((response) => {
          if (props.config?.responseType === "blob") {
            return response;
          }
          const { data } = response;

          return data;
        })
        .catch((error) => {
          throw error;
        });
    },
    [isMockUrl]
  );

  interface IPostRequestProps {
    url: string;
    config?: any;
    postData?: any;
  }

  const makePostRequest = useCallback(
    async (props: IPostRequestProps): Promise<any> => {
      const { url, config, postData } = props;

      const requestUrl = isMockUrl(url);

      return axios
        .post(requestUrl, postData, {
          withCredentials: true,
          ...config,
        })
        .then((response) => {
          const { data } = response;

          return data;
        })
        .catch((error) => {
          throw error;
        });
    },
    [isMockUrl]
  );

  return {
    makeGetRequest,
    makePostRequest,
  };
};
