import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NavigationState {
  planRef?: string;
  benefitRef?: string;
  fundKey?: string;
}

const initialState: NavigationState = {};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setNavigation: (
      state: NavigationState,
      action: PayloadAction<NavigationState>
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setNavigation } = navigationSlice.actions;
export default navigationSlice.reducer;
