import { FocusEvent } from "react";
import { DatePicker } from "antd";
import moment from "moment";

interface Props {
  value: moment.Moment;
  onChange: (id: string, d: moment.Moment) => void;
  onValidation: (id: string, d: moment.Moment) => void;
  error: string;
  id: string;
  placeholder?: string;
}
export const BaseDatePicker = ({
  value,
  onChange,
  onValidation,
  error,
  id,
  placeholder,
}: Props) => {
  return (
    <div>
      <div className="border border-gray-300">
        <DatePicker
          format={"DD/MM/YYYY"}
          id={id}
          value={value}
          onChange={(d: moment.Moment) => {
            onChange(id, d);
            onValidation(id, d);
          }}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {}}
          style={{
            border: "none",
            color: "red",
            paddingTop: 20,
            paddingBottom: 20,
            fontSize: 20,
          }}
          placeholder={placeholder}
          className="h-12"
          data-testid={`${id}Field`}
        />
      </div>
      {!!error && <div className="text-xs text-red-800">{error}</div>}
    </div>
  );
};
