import { ApiResponseLogin } from "api-response/api-response-login";
import { Endpoint } from "endpoints";
import { useApi } from "hooks/useApi";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { setAuth } from "redux/authSlice";
import { useAppDispatch } from "redux/hooks";
import { setUser, UserState } from "redux/userSlice";

export const useLogin = () => {
  const { makePostRequest, makeGetRequest } = useApi();
  const [loginResponse, setLoginResponse] = useState<ApiResponseLogin>(null);
  const [loginError, setLoginError] = useState("");

  const dispatch = useAppDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (loginResponse) {
      if (!loginResponse.authenticated) {
        setLoginError(
          "Invalid login details, please check your information. Further attempts could result in your account being locked for a period of time."
        );
      }
    } else {
      setLoginError("");
    }
  }, [loginResponse]);

  const getUserDetails = useCallback(
    async (token: string) => {
      console.log("enter getUserDetails");
      window.sessionStorage.setItem("storageToken", token);
      await dispatch(
        setAuth({
          token,
        })
      );

      // makeGetRequest({
      //   url: Endpoint.getPortalDetails,
      // }).then((response) => {
      //   console.log({ response });
      //   const cl: UserState = {
      //     customerKey: response.customerKey,
      //     iterationId: response.iterationId,
      //     customerSecret: response.customerSecret,
      //     customerRef: response.customerRef,
      //     firstName: response.firstName ?? "",
      //     middleName: response.middleName ?? "",
      //     lastName: response.lastName ?? "",
      //     dob: response.dob,
      //     ni: response.ni ?? "",
      //     address: response.address[0],
      //     emailAddress: response.emailAddress ?? "",
      //     mobilePhoneNumber: response.mobilePhoneNumber ?? "",
      //     marketingEmailOptIn: response.marketingEmailOptIn,
      //     marketingSMSOptIn: response.marketingSMSOptIn,
      //     membershipEmailOptIn: response.membershipEmailOptIn,
      //     membershipSMSOptIn: response.membershipSMSOptIn,
      //     keepInformedViaSMS: response.keepInformedViaSMS,
      //     nameChanged: response.nameChanged,
      //     mpMarketingStatus: response.mpMarketingStatus,
      //     servicingAdviser: response.servicingAdviser,
      //     hasUnreadNotification: response.hasUnreadNotification,
      //     firstTimeLoggedIn: response.firstTimeLoggedIn,
      //     isDiagnosticsEnabled: response.isDiagnosticsEnabled,
      //   };
      //   dispatch(setUser(cl));
      // });
    },
    [dispatch]
  );

  const sendCredentials = useCallback(
    async (email: string, password: string) => {
      // const gReCaptchaToken = await executeRecaptcha("Login");

      const response: ApiResponseLogin = await makePostRequest({
        url: Endpoint.login,
        postData: {
          f_un: email,
          f_pw: password,
          act: "check_user_pass",
          // gReCaptchaToken,
        },
      });

      setLoginResponse(response);
      return response;
    },
    [makePostRequest]
  );

  return {
    loginError,
    sendCredentials,
    getUserDetails,
  };
};
