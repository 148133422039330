import { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { cloneDeep } from "lodash";
import { FormValuesNew } from "types/form-values-new";

const isValidNin = (value: string) => {
  const expNi =
    /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/;
  const regexNi = new RegExp(expNi);
  return regexNi.test(value as string);
};

const isValidPlan = (str: string): boolean => {
  const expPlan = [
    /^IS(\d){8}A$/,
    /^IS(\d){8}J$/,
    /^V(\d){6}(D|E|F){1}$/,
    /^SI(\d){8}A$/,
    /^CT(\d){8}A$/,
    /^C(\d){6}A$/,
  ];

  return expPlan.some((ex) => {
    const regEx = new RegExp(ex);
    return regEx.test(str);
  });
};

const passwordRules = (password: string) => {
  const rules = ["12 characters", "numbers", "!#$%@", "upper and lower case"];

  return rules.map((r) => {
    if (r === "12 characters") {
      return {
        text: r,
        completed: password.length >= 12 && password.length <= 512,
      };
    } else if (r === "numbers") {
      return {
        text: r,
        completed: new RegExp(/\d/).test(password),
      };
    } else if (r === "!#$%@") {
      return {
        text: r,
        completed: new RegExp(/(!|#|\$|%|@)/).test(password),
      };
    } else if (r === "upper and lower case") {
      const hasUpper = new RegExp(/[A-Z]/).test(password);
      const hasLower = new RegExp(/[a-z]/).test(password);
      return {
        text: r,
        completed: hasUpper && hasLower,
      };
    }

    return {
      text: r,
      completed: false,
    };
  });
};

export const useFormNew = (initValues: FormValuesNew) => {
  const [draftValues, setDraftValues] = useState<FormValuesNew>(
    cloneDeep(initValues)
  );

  const setErrorField = useCallback(
    (fieldName: string, errorMessage: string) => {
      draftValues.get(fieldName).error = errorMessage;
      setDraftValues(new Map(draftValues));
    },
    [draftValues]
  );

  const setTouched = useCallback(
    (fieldName: string) => {
      draftValues.get(fieldName).touched = true;
      setDraftValues(new Map(draftValues));
    },
    [draftValues]
  );

  const onFieldValueChange = useCallback(
    (id: string, value: string | number | moment.Moment | boolean) => {
      console.log("onFieldValueChange");
      draftValues.get(id).value = value;
      setDraftValues(new Map(draftValues));
    },
    [draftValues]
  );

  const resetForm = useCallback(() => {
    console.log("enter resetForm()", initValues);
    setDraftValues(initValues);
  }, [initValues]);

  const validateEmail = (email: string) => {
    return (
      email.length <= 50 &&
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  };

  const formHasErrors = useMemo(() => {
    const fields = [...draftValues.values()];
    return fields.some((field) => field.error && field.error !== "");
  }, [draftValues]);

  const allRequiredTouched = useMemo(
    () =>
      [...draftValues.values()].every(
        (field) => !field.required || (field.required && field.touched)
      ),
    [draftValues]
  );

  const allRequiredHaveValues = useMemo(
    () =>
      [...draftValues.values()].every(
        (field) => !field.required || (field.required && !!field.value)
      ),
    [draftValues]
  );

  const fieldValidation = useCallback(
    (
      fieldName: string,
      value: string | number | boolean | moment.Moment,
      compareWith?: string | number | boolean | moment.Moment
    ) => {
      console.log("enter fieldValidation");
      setTouched(fieldName);

      if (fieldName === "email") {
        if (!validateEmail(value as string)) {
          setErrorField(fieldName, "Please enter a valid Email");
          return;
        }
      } else if (fieldName === "planOrNiNumber") {
        const validNi = isValidNin(value as string);
        const validPlan = isValidPlan(value as string);

        if (!validNi && !validPlan) {
          setErrorField(
            fieldName,
            "Something isn’t right. Please check your details. MyPlans is available for all Child Trust Funds, Junior ISAs, ISAs and Savings & Investment Plans (single Plans)."
          );
          return;
        }
      } else if (fieldName === "dob") {
        const dobError = "Please enter a valid Date of birth";

        if (!moment.isMoment(value)) {
          setErrorField(fieldName, dobError);
          return;
        }

        const age = moment().diff(value, "years", true);
        if (age < 0 || age >= 120) {
          setErrorField(fieldName, dobError);
          return;
        }
      } else if (fieldName === "declaration") {
        if (!value) {
          setErrorField(fieldName, "Please check declaration");
          return;
        }
      } else if (
        ["firstName", "middleName", "lastName", "surname"].includes(
          fieldName as string
        )
      ) {
        const exp =
          /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]){2,}$/;
        const regex = new RegExp(exp);
        if (!regex.test(value as string)) {
          setErrorField(fieldName, "Please enter a valid name");
          return;
        }
      } else if (fieldName === "emailConfirm") {
        if (
          !!compareWith &&
          (compareWith as string).toLowerCase() !==
            (value as string).toLowerCase()
        ) {
          setErrorField(fieldName, "Your email does not match");
          return;
        }
        if (!validateEmail(value as string)) {
          setErrorField(fieldName, "Please enter a valid Email");
          return;
        }
      } else if (fieldName === "password") {
        const passwordHasErrors = !!passwordRules(value as string).find(
          (r) => !r.completed
        );
        if (passwordHasErrors) {
          setErrorField(fieldName, " ");
          return;
        }
      } else if (fieldName === "passwordConfirm") {
        if (compareWith !== value) {
          setErrorField(fieldName, "The password does not match");
          return;
        }
      } else if (fieldName === "agreeTermsConditions") {
        if (!value) {
          setErrorField(fieldName, "Please agree with terms and conditions");
          return;
        }
      } else if (fieldName === "optSms") {
        // setTimeout(() => fieldValidation("mobile", draftValues.mobile.value), 0);
      } else if (fieldName === "mobile") {
        const exp = /^(?:(?:00)?(?:\+)?44|0)7(?:[45789]\d{2}|624)\d{6}$/;
        const regex = new RegExp(exp);
        const noSpaces = value.toString().replaceAll(" ", "");

        if ((noSpaces || compareWith) && !regex.test(noSpaces)) {
          setErrorField(fieldName, "Please enter a valid mobile number");
          return;
        }
      } else if (fieldName === "readPrivacyPolicy") {
        if (!value) {
          setErrorField(
            fieldName,
            "Please read and agree with the Privacy Policy"
          );
          return;
        }
      } else if (fieldName === "withdrawAmount") {
        if (value > compareWith) {
          setErrorField(fieldName, "The amount entered exceeds the Plan value");
          return;
        }
      } else if (fieldName === "saveAmount") {
        if (value > compareWith) {
          setErrorField(fieldName, "The amount entered exceeds the Plan value");
          return;
        }
        if (value < 20 && value > 0) {
          setErrorField(
            fieldName,
            "Please enter a minimum of £20 to your ISA."
          );
          return;
        }
      } else if (draftValues.get(fieldName).required && !value) {
        setErrorField(fieldName, "Required");
        return;
      }

      setErrorField(fieldName, "");
      return true;
    },
    [draftValues, setErrorField, setTouched]
  );

  const changeRequired = useCallback(
    (id: string, value: boolean) => (draftValues.get(id).required = value),
    [draftValues]
  );

  return {
    changeRequired,
    resetForm,
    draftValues,
    fieldValidation,
    formHasErrors,
    allRequiredTouched,
    onFieldValueChange,
    passwordRules,
    allRequiredHaveValues,
    isValidNin,
    isValidPlan,
  };
};
