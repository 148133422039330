import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const WhiteCard: FC<Props> = ({
  children,
  className = "w-full bg-white",
}) => {
  return <div className={`p-4 rounded shadow ${className}`}>{children}</div>;
};
