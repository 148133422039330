import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";
import "./index.css";
// import "antd/dist/antd.css.min";
import crossBrowserListener from "./utils/reduxpersist-listener";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { injectStore } from "./utils/api";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
injectStore(store);

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: hardSet,
};

window.addEventListener("storage", crossBrowserListener(store, persistConfig));

ReactDOM.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
      // language="[optional_language]"
      // useRecaptchaNet="[optional_boolean_value]"
      // useEnterprise="[optional_boolean_value]"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <App />
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById("root")
);
