export enum Endpoint {
  additionalVerification = "/api_verification",
  authenticateEmail = "/api_authenticate_email",
  authenticateLogin = "/api_authenticate_login",
  authenticateOtp = "/api_authenticate_otp",
  documentDownload = "/api/Portal/downloaddocument",
  documents = "/api/Portal/getdocuments",
  findAddresses = "/api/Customer/searchforaddress",
  findMyPlan = "/api/Account/registration",
  fund = "/api/Portal/getfundinfo",
  fundPerformance = "/api/Portal/getfundperformance",

  funds_CMCTFN = "/api_contribution-amount-page_CMCTFN",
  funds_SIP = "/api_contribution-amount-page_SIP",

  generateOtp = "/api/Account/generateOTP",
  getAddressDetails = "/api/Customer/searchforselectedaddressdetails",
  getHistory = "/api/Portal/getplanactivityhistory",
  getPortalDetails = "/api/Portal/getportaldetails",
  getRegisterdUserDetails = "/api/Account/createuseraccount",
  isEmailUnique = "/api/Validation/isemailaddressunique",
  login = "/",
  notificationRead = "/api/Portal/setnotificationread",
  notifications = "/api/Portal/getnotifications",
  registerUser = "/api_register_user",
  support = "/support_endpoint.test",

  userProfileChangeName = "/api/Portal/updatecustomername",
  userProfileChangeAddress = "/api/Portal/updatecustomeraddress",
  userProfileChangeEmail = "/api/Portal/updatecustomeremail",
  userProfileChangeMobile = "/api/Portal/updatecustomermobile",
  userProfileChangePassword = "/api/Portal/updateaccountpassword",
  userProfileChangeMarketing = "/api/Portal/updatecustomermarketingpreference",
  userProfileChangeMembership = "/api/Portal/updatecustomermembershippreference",
  userProfileKeepInformedSms = "/api/Portal/updatecustomerkeepinformedviasms",

  validateOtp = "/api/Account/validateOTP",
  forgottenPassword = "/api/Account/forgottenpassword",
  forgottenPasswordVerifyKey = "/api/Account/verifyforgottenpassword",
  forgottenPasswordChange = "/api/Account/changeforgottenpassword",
  forgottenEmail = "/api/Account/forgottenemailaddress",

  getCountries = "/api/countries",
  fundAllocations = "/api/fund-allocations",
}
