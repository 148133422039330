import { UserOutlined } from "@ant-design/icons";
import { FC } from "react";

interface Props {
  onClick: () => void;
  userFullName: string;
}
export const UserButton: FC<Props> = ({ onClick, userFullName }) => {
  return (
    <div onClick={onClick} className="flex items-center cursor-pointer">
      <div className="relative flex items-center justify-center w-8 h-8 text-xl border-2 border-pink-900 rounded-full">
        <UserOutlined />
      </div>
      <div className="ml-2 text-lg font-semibold ">{userFullName}</div>
    </div>
  );
};
