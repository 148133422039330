import { useTrackEvents } from "hooks/useTrackEvents";
import { FC } from "react";
interface Props {
  id?: string;
  className?: string;
  onClick?: () => void;
  children: string;
  noTrack?: boolean;
}
export const BaseButton: FC<Props> = (props) => {
  const { trackEvent } = useTrackEvents();
  const { noTrack, ...restProps } = props;
  return (
    <button
      {...restProps}
      onClick={() => {
        if (noTrack === undefined || noTrack === false) {
          trackEvent(
            {
              id: props.id,
              objType: "button",
              eventType: "click",
              caption: !!props.children
                ? JSON.stringify(props.children)
                : "N/A",
            },
            noTrack
          );
        }
        props.onClick && props.onClick();
      }}
    />
  );
};
