import { BaseButton } from "features/enhanced-controls/BaseButton";
import { FC } from "react";

interface Props {
  caption: string;
  onClick: () => void;
  noTrack?: boolean;
  disabled?: boolean;
}
export const ButtonDarkRounded: FC<Props> = ({
  onClick,
  caption,
  noTrack,
  disabled = false,
}) => {
  const bgColor = disabled ? "bg-gray-500" : "bg-plum";

  return (
    <BaseButton
      className={`px-5 whitespace-nowrap py-2 md:px-10 font-semibold text-white cursor-pointer rounded-3xl ${bgColor}`}
      onClick={() => onClick && onClick()}
      noTrack={noTrack}
    >
      {caption}
    </BaseButton>
  );
};
